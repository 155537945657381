import React, { useEffect, useState } from 'react'
// import "./Contact.css";
import Styles from "./getinTouch.module.css";
import { BiTimeFive } from 'react-icons/bi';
import { AiFillHome } from 'react-icons/ai';
import { IoMdContacts } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import {
    Box,
    VStack,
    HStack,
    Input,
    Textarea,
    Text,
    Button,
    useToast,
    FormControl,
    FormLabel,
    FormErrorMessage,

} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
const init = {
    "name": "",
    "email": "",
    "mobile": "",
    "projectType": "",
    "projectdetails": ""
}
const GetinTouch = () => {
    const [contactdata, setcontactdata] = useState(init);
    const [hasError, setHasError] = useState({});
    const toast = useToast();





    useEffect(() => {
        const options = {
            top: 0,
            left: 0,
            behavior: 'smooth'
        };
        window.scrollTo(options);
    }, []);





    const validateForm = () => {
        const errors = {};

        if (!contactdata.name) {
            errors.name = "required*";
        }

        if (!contactdata.email) {
            errors.email = "required*";
        }

       

        if (!contactdata.mobile) {
            errors.mobile = "required*";
        } else if (!/^\d{10}$/.test(contactdata.mobile)) {
            errors.mobile = "enter 10 digit mobile number"
        }


        return errors;
    };



    const handalechange = (e) => {
        const { name, value } = e.target;
        setcontactdata({ ...contactdata, [name]: value })
        
    }

    const handalesubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();


        if (Object.keys(validationErrors).length > 0) {
            setHasError(validationErrors);
            return;
        } else {
            setHasError({});
            const{name,email,mobile,projectType,projectdetails}=contactdata;
            let data={name,email,mobile,projectType,projectdetails};
            if(data.projectType==""){
                data.projectType="none";
                
            }
            if(data.projectdetails==""){
                data.projectType="none";
                
            }
            fetch(`${process.env.REACT_APP_Backend_baseUrl}/contact`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data)
            }).then(res => {
                if (res.status === 200) {
                    toast({
                        title: 'Success',
                        description: "we will get in touch with you soon",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    setHasError({})
                    setcontactdata(init)

                } else {
                    toast({
                        title: 'field',
                        description: "failed to save contact details",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                    setHasError({})
                    setcontactdata(init)
                }

            }).catch(err => {
                toast({
                    title: 'field',
                    description: err.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                setHasError({})
                setcontactdata(init)
            })


        }
    }







    return (
        <div className={Styles.contact}>
          
            
            <div className={Styles.contact_bottom}>
                <div className={Styles.contact_bottom_left}>
                    <p className={Styles.title}>Get In Touch</p>
                    <h1>The internet is pretty huge,<br/> We're so glad you found us</h1>
                    <div className={Styles.addres_contaner}>
                        <p className={Styles.logo_contaner}><BiTimeFive className={Styles.logo} />  9:30-6:30 IST</p>
                        <div className={Styles.address_card}>
                            <p className={Styles.logo_contaner2}><MdEmail className={Styles.logo} />Email</p>
                            <p className={Styles.address}>info@techmapperz.com</p>
                        </div>
                        <div className={Styles.address_card}>
                            <p className={Styles.logo_contaner2}><IoMdContacts className={Styles.logo} />Phone no</p>
                            <p className={Styles.address}>+91-9643002065 / +91-3335752689</p>
                        </div>
                        <div className={Styles.address_card}>
                            <p className={Styles.logo_contaner2}><AiFillHome className={Styles.logo} />Delhi</p>
                            <p className={Styles.address}>55, Lane - 2, Westend Marg, Saidullajab,<br/>
                                Near Saket metro station, New Delhi - 110030, India</p>
                        </div>
                        <div className={Styles.address_card}>
                            <p className={Styles.logo_contaner}><AiFillHome className={Styles.logo} />Kolkata</p>
                            <p>CF 401, Block CF, Sector 1, Salt Lake, Kolkata-700064, India</p>
                        </div>
                    </div>
                </div>
                <div className={Styles.contact_bottom_right}>
                    <VStack  spacing={8}>
                        <HStack  spacing={[4,14,14]}>
                            <FormControl isInvalid={hasError.name}>
                                <FormLabel>Full Name*</FormLabel>
                                <Input
                                    name="name"
                                    value={contactdata.name}
                                    onChange={handalechange}
                                    placeholder="Enter Full Name"
                                />
                                <FormErrorMessage>{hasError.name}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={hasError.email}>
                                <FormLabel>Email*</FormLabel>
                                <Input
                                    name="email"
                                    value={contactdata.email}
                                    onChange={handalechange}
                                    placeholder="Enter Email"

                                />

                                <FormErrorMessage>{hasError.email}</FormErrorMessage>
                            </FormControl>
                        </HStack>

                        <HStack spacing={[4,14,14]}>
                            <FormControl isInvalid={hasError.projectType}>
                                <FormLabel>Project Type</FormLabel>
                                <Input
                                    name="projectType"
                                    value={contactdata.projectType}
                                    onChange={handalechange}
                                    placeholder="Enter Project Type"
                                   
                                />
                                <FormErrorMessage>{hasError.projectType}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={hasError.mobile}>
                                <FormLabel>Mobile*</FormLabel>
                                <Input
                                    name="mobile"
                                    value={contactdata.mobile}
                                    onChange={handalechange}
                                    placeholder="Enter Mobile"
                                    type="tel"
                                    pattern="\d{10}"

                                />
                                <FormErrorMessage>{hasError.mobile}</FormErrorMessage>
                            </FormControl>
                        </HStack>

                        <HStack  className={Styles.message} w={["100%","90%","90%"]} >
                        <FormControl isInvalid={hasError.projectdetails}>
                            <FormLabel>Write Project Details*</FormLabel>
                            <Textarea
                                className={Styles.message_textarea}
                                name="projectdetails"
                                value={contactdata.projectdetails}
                                onChange={handalechange}
                                placeholder="Write Project Details"
                                h={"200px"}
                                width={"100%"}
                            />
                            <FormErrorMessage>
                                {hasError.projectdetails}
                            </FormErrorMessage>
                        </FormControl>
                        </HStack>

                        <Button  size={"lg"} w="40%" colorScheme="blue" onClick={handalesubmit}>
                            Submit
                        </Button>
                    </VStack>
                </div>
            </div>
           
        </div>
    )
}

export default GetinTouch

