import React from 'react'
import './gisServices.css'
import GIS1 from '../../Photos/image279.png'
import GIS2 from '../../Photos/image280.png'
import GIS3 from '../../Photos/image281.png'
import GIS4 from '../../Photos/image282.png'
import GIS5 from '../../Photos/image283.png'
import GIS6 from '../../Photos/image284.png'
import GetinTouch from '../../Components/GetinTouch/GetinTouch'
import { Link } from 'react-router-dom'


function GisServices() {

    return (
        <>


            <div className='font-poppins' id='CaseStudiesGISServices'>
                <div className='itbreadcramp flex-col h-[350px] text-center items-center justify-center'>
                    <p className='text-white mt-[66px] mb-[66px] text-[66px] font-bold max-sm:text-[50px]'>GIS Services</p>
                    <p className='text-white'>Case Studies / GIS Services</p>

                </div>
                <p className='f0nt-normal text-center mb-0 text-[26px] p-16 text-white max-sm:p-4 max-sm:text-justify max-sm:text-[24px]'>TechMapperz specializes in GIS solutions, offering mapping, spatial analysis, and data visualization services tailored to clients' geographic data needs, employing cutting-edge technology to provide precise, efficient, and insightful spatial solutions for diverse industries.</p>

                <div className=' Background900'>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>

                        <p className='f0nt-normal text-center text-[22px] text-gray-400  max-sm:text-justify'>In our recent project, we've utilized advanced drone imagery to develop comprehensive maps for various Industrial Areas. This process included the meticulous digitization of over twenty distinct layers for each region,</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>

                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={GIS1} />
                </div>

                <div className='Background700 '>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>

                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>We have prepared map for City Mapping. Here we have used Drone Imagery as the source of digitization. We captured all the features in 1:100 scale</p>


                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={GIS2} />

                </div>

                <div className=' Background900'>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>

                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>We have created Agricultural map of a particular area using Drone Imagery. Eventually, we have classified the agricultural land on the basis of crop.</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>

                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={GIS3} />
                </div>

                <div className='Background700 '>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>

                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>We have used Drone Imagery for digitization and mapping of habitation areas. We have digitized more than Eight (8) Layers in this project and more than 8 other sub layers too as per the individual areas. All the feature extraction is done in 1:50 scale.</p>


                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={GIS4} />

                </div>

                <div className='Background900'>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>

                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>We have done the Landuse and Landcover mapping with the help of Higher esolution drone imagery. Digitization of more than 12 layers and 9 sub layers for mapping are done.</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={GIS5} />
                </div>



                <div className='Background700 '>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>

                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>Georeferencing of images are done in this project. Digitization of sheets of a particular Village or Tehsil is done when the sheets are aligned after Georeferencing.</p>


                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={GIS6} />

                </div>





                <GetinTouch />

            </div>


        </>
    )
}

export default GisServices
