import React, { useEffect } from 'react';
import Styles from './CRM_page.module.css'
import CRM1 from '../../Photos/CRM1.png'
import CRM2 from '../../Photos/CRM2.png'
import CRM3 from '../../Photos/CRM3.png'
import CRM4 from '../../Photos/CRM4.png'
import CRM5 from '../../Photos/CRM5.png'
import CRM6 from '../../Photos/CRM6.png'
import CRM7 from '../../Photos/CRM7.png'
import CRM8 from '../../Photos/CRM8.png'
import CRM9 from '../../Photos/CRM9.png'
import CRM10 from '../../Photos/CRM10.png'

import T01 from '../../Photos/01.png';
import T02 from '../../Photos/02.png';
import T03 from '../../Photos/03.png';
import T04 from '../../Photos/04.png';
import { Link } from 'react-router-dom';




const CRM_pages = () => {


    useEffect(() => {
        const options = {
            top: 0,
            left: 0,
            behavior: 'smooth'
        };
        window.scrollTo(options);
    }, []);

    return (
        <div className={Styles.mobile_app_development}>
            <section className={Styles.first_part}>
                <h1>CRM-ERP</h1>
                <p> <Link to={"/"}>Home</Link> /<Link to={"/service"}> Services</Link> /CRM-ERP</p>
            </section>



            <section className={Styles.secand_part}>
            <div className={Styles.watermark}>IT Service</div>
            <div className={Styles.watermark2}>?</div>
                <div className={Styles.harbour}>
                    <p className={Styles.heading}>Everything CRM, All-round assistance, We are one stop solution for your CRM needs</p>
                    <div className={Styles.design}>

                        <div className={Styles.card1}>
                            <div className={Styles.testimg1}>
                                <img src={CRM1} alt='' />
                                <img className={Styles.num} src={T01} alt='' />
                            </div>

                            <Link to={"/crm_consulting"}><h3>CRM Consulting</h3></Link>
                            <p>With the accumlated experince of more than 20 years, our team is well equipped to help you solve all possible challenges in the coustomer management field...<br/><b style={{"borderBottom":"1px solid red","fontStyle":"italic"}}><Link to={"/crm_consulting"}>Know More</Link></b></p>
                        </div>


                        <div className={Styles.card1}>
                            <div className={Styles.testimg1}>
                                <img src={CRM2} alt='' />
                                <img  className={Styles.num} src={T02} alt='' />
                            </div>

                            <Link to={"/crm_implement"}> <h3>CRM Implementation</h3></Link>
                            <p>With global competition and greater customer expectations companies look forward to enhance customer relationship and experience,many business entities...<br/> <b style={{"borderBottom":"1px solid red","fontStyle":"italic"}}><Link to={"/crm_implement"}> Know More</Link></b></p>

                        </div>


                        <div className={Styles.card1}>
                            <div className={Styles.testimg1}>
                                <img src={CRM3} alt='' />
                                <img className={Styles.num} src={T03} alt='' />
                            </div>

                            <Link to={"/custom_consulting"}>  <h3>Custom CRM Consulting </h3></Link>
                            <p>AT customer CRM Consulting we understand your current capabilities, map your organisation's desired future state, and then recommend CRM implementation... <br/><b style={{"borderBottom":"1px solid red","fontStyle":"italic"}}><Link to={"/custom_consulting"}>Know More</Link></b></p>

                        </div>


                        <div className={Styles.card1}>
                            <div className={Styles.testimg1}>
                                <img src={CRM4} alt='' />
                                <img className={Styles.num} src={T04} alt='' />
                            </div>

                            <Link to={"/crm_integretion"}> <h3>CRM Integration</h3></Link>
                            <p>CRM integration is connecting all the applications you use to run your business to customer Information,It's a 360-degree view of customer data that can be seamlessly...<br/><b style={{"borderBottom":"1px solid red","fontStyle":"italic"}}><Link to={"/crm_integretion"}>Know More</Link></b> </p>


                        </div>
                       
                    </div>
                   
                </div>


                <div className={Styles.Application}>
                    <h3 className={Styles.heading2}>Types of CRM </h3>
                    <div className={Styles.design}>


                        <div className={Styles.card5}>

                            <img src={CRM5} alt='' />
                            <h3>Operational</h3>
                            <p>This is a prime tool to manage your business, more efficiently with maximum utilization of customer data.</p>
                        </div>


                        <div className={Styles.card6}>
                            <img src={CRM6} alt='' />
                            <h3>Analytical</h3>
                            <p>An analytic CRM is for better interactions with your customers and new business possibilities based on customer data.</p>

                        </div>


                        <div className={Styles.card7}>
                            <img src={CRM7} alt='' />
                            <h3>Collaborative</h3>
                            <p>This CRM is the framework for vendor distributor interaction and business opportunities.</p>
                        </div>

                        <div className={Styles.card8}>
                            <img src={CRM8} alt='' />
                            <h3>Campaign Management</h3>
                            <p>Campaign CRM is primarly for campaign management which combines anaytical and operational CRMs.</p>
                        </div>


                        <div className={Styles.card9}>
                            <img src={CRM10} alt='' />
                            <h3>Strategic</h3>
                            <p>This is a market study CRM, which helps you to understand and fellow market trends and demands, crucial for the customer demand supply efficiency.</p>
                        </div>

                    </div>
                </div>
                {/* <div className={Styles.video_contaner}>
                    <div className={Styles.crm_video}></div>
                    <p>At custom CRM consulting we understand your current capabilities, map your organisation's desired future state, and then recommend CRM implementation Know More</p>
                    <Link to={"#"}><b>Know more</b></Link>
                </div> */}
            </section>
        </div>
    )
}

export default CRM_pages

