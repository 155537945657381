import React from 'react'
import './itServices.css'
import Fabcon1 from '../../Photos/MarketingScreen.png'
import Fabcon2 from '../../Photos/Homescreen.png'
import Fabcon3 from '../../Photos/VIEWProductScreen(1).png'
import SHRC1 from '../../Photos/shrc-all1.png'
import AutoSource1 from '../../Photos/AutoSource21.png'
import AutoSource2 from '../../Photos/AutoSource11.png'
import AutoSource3 from '../../Photos/AutoSource12.png'
import Welho1 from '../../Photos/Welho-all-2.png'
import WhiteSpread1 from '../../Photos/wsfl1.png'
import ManuserGhorbari1 from '../../Photos/manuser-ghorbaari1.png'
import CoCreateLab1 from '../../Photos/Co-Createall2.png'
import TechmapperzCRM1 from '../../Photos/Group1000004122.png'
import { Link } from 'react-router-dom'


function ItServices() {

    return (
        <>


            <div className='font-poppins' id='CaseStudiesITServices'>
                <div className='itbreadcramp flex-col h-[350px] text-center items-center justify-center'>
                    <p className='text-white  mt-[66px] mb-[66px] text-[66px] font-bold max-sm:text-[50px]'>IT Services</p>
                    <p className='text-white'>Case Studies / IT Services</p>

                </div>


                <p className='f0nt-normal text-center mb-0 text-[26px] p-16  text-white max-sm:p-4 max-sm:text-justify max-sm:text-[24px] '>TechMapperz offers software development, web design, mobile app development, digital marketing, and IT consulting services. We collaborate with clients to create tailored solutions using the latest technology, aiming to provide creative, dependable, and adaptable solutions for business expansion.</p>


                <div className='Background800 flex px-16 justify-center items-center max-sm:flex-col max-sm:px-0'>
                    <div className='w-[50%]  max-sm:p-4 max-sm:pb-16 max-sm:w-[100%]'>
                        <p className='text-white  text-[36px] font-medium max-sm:text-center'>Fabcon</p>
                        <p className='f0nt-normal text-[22px] serviceItParagraph text-gray-400'>Elevate your interior design skills with Fabcon, a user-friendly app that helps you optimize your space. From smart storage solutions to expert tips, this app empowers you to maximize the potential of any room while maintaining your personal style."</p>
                        <div className='flex absolute mt-[15px] '>
                            <div className='serviceKnowmore bg-blue-500 '></div>
                            <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                        </div>
                    </div>
                    <div className='flex justify-center items-center'>
                        <div className=''>
                            <img src={Fabcon1} />
                            <img src={Fabcon2} />
                        </div>
                        <img className='w-[50%] ' src={Fabcon3} />
                    </div>
                </div>


                <div className=' Background900'>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>
                        <p className='text-white text-center   text-[36px] font-medium'>Special Human Rights Commission</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400  max-sm:text-justify '>Special Human Rights comissions is a civil rights’ group comprised of advocates, social activists and grassroots para- legal social workers dedicated to using the legal system to protect and advance the civil and human rights in India. SHRC, a non- profit and governmental civil rights’ group was set up in 2013 to defend the rights of the underprivileged sections of the society.</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img className='w-[100%] m-auto pb-16' src={SHRC1} />
                </div>


                <div>
                    <div className='Background800 p-16 max-sm:p-4 max-sm:pb-16'>
                        <p className='text-white text-center  text-[36px] font-medium'>Premium Auto Source</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>Experience optimal performance and efficiency with PAS Auto Source, a leading manufacturer of automotive drivetrain components. Visit our website to explore our innovative drivetrain solutions, including powertrains, driveline systems, and hybrid technologies, designed to meet the demanding needs of modern vehicles."</p>
                        <div className='flex justify-center mt-[15px] pb-4'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <img className='m-auto w-[100%]' src={AutoSource1} />
                        <div className='flex px-[16%] pb-16 mt-[-2%] items-center Background900 justify-between'>
                            <img className='w-[47%]' src={AutoSource2} />
                            <img className='w-[47%]' src={AutoSource3} />
                        </div>
                    </div>
                </div>

                <div className='Background700 '>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>
                        <p className='text-white text-center  text-[36px] font-medium'>Welho</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>Say hello to Welho..! Welcome to the world of wellness. Made with high-quality natural ingredients and no artificial colours or flavours, our dairy-based refreshing beverages offer the perfect blend of nutrition and taste. Each Welho product comes with a revolutionary, nutrient-balanced formula that ensures your daily wholesome nourishment.</p>


                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'> <Link to={"/contact"}>Know More</Link></button>
                            </div>
                        </div>
                    </div>
                    <img className='m-auto pb-8' src={Welho1} />

                </div>


                <div className='itservicepage '>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>
                        <p className='text-white text-center  text-[36px] font-medium'>White Spread Foods</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>To begin with, we procure the best quality milk directly from farmers. We then put it through stringent quality checks in our chilling plants to make it even better. It is then preserved at exactly 40 Celsius and packaged with utmost care so that every drop stays delicious when it reaches you.</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'>Know More</button>
                            </div>
                        </div>
                    </div>
                    <img className='m-auto' src={WhiteSpread1} />
                </div>

                <div className='Background700 flex gap-[50px] p-16 justify-center items-center max-sm:p-4 max-sm:pb-16 max-sm:flex-col-reverse'>
                    <img className='m-auto w-[100%] h-[500px] max-sm:h-[300px] max-sm:mt-[20px]' src={ManuserGhorbari1} />
                    <div className=''>
                        <p className='text-white text-center  text-[36px] font-medium'>Manuser Ghorbari</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400 serviceItParagraph max-sm:text-justify'>Manusher Ghorbari is not just a weekend destination only. Here one may experience a unique way of living life. Being inspired by Atin Bandopadhyay's four-part tetralogy on Partition (Nilkantha Pakhir Khonje, "Manusher Ghorbari" Aloukik Jalajan and Ishwarer Bagan), Aniket, named his dream project upon its second part.</p>
                        <div className='flex absolute max-sm:mt-[15px] max-sm:pb-4'>
                            <div className='serviceKnowmore bg-blue-500 '></div>
                            <button className='text-white  text-[26px] font-bold relative ml-[-25px]'>Know More</button>
                        </div>
                    </div>
                </div>


                <div className=' Background900'>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>
                        <p className='text-white text-center  text-[36px] font-medium'>CoCreate Lab</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>A game-changer in the startup ecosystem It enables access to a global platform that brings together the best and brightest entrepreneurs, investors, mentors, and philanthropic organizations in an integrated ecosystem.With a network of world-class mentors from companies like Meta, Netflix, Uber, Shell, and Harvard University, along with the World Bank and OECD, and other multi-laterals, CoCreate Labs is set to revolutionize the way start-ups are launched and grown.</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'>Know More</button>
                            </div>
                        </div>

                    </div>

                    <img className='m-auto w-full ' src={CoCreateLab1} />
                </div>

                <div className='Background700'>
                    <div className='p-16 max-sm:p-4 max-sm:pb-16'>
                        <p className='text-white text-center   text-[36px] font-medium'>Techmapperz CRM</p>
                        <p className='f0nt-normal text-center text-[22px] text-gray-400 max-sm:text-justify'>Our CRM web app offers seamless customer management, allowing you to track interactions, manage leads, and nurture relationships all in one place. Stay organized, improve customer satisfaction, and drive growth with our user-friendly interface.</p>
                        <div className='flex justify-center mt-[15px]'>
                            <div className='flex absolute  '>
                                <div className='serviceKnowmore bg-blue-500 '></div>
                                <button className='text-white  text-[26px] font-bold relative ml-[-25px]'>Know More</button>
                            </div>
                        </div>
                    </div>
                    <img className='w-[75%] m-auto pb-8 max-sm:w-[95%]' src={TechmapperzCRM1} />
                </div>



            </div>
        </>
    )
}

export default ItServices
