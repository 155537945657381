import React, { useEffect } from "react";
import styles from './Mobile_development.module.css'
import Development6 from '../../Photos/Development6.png';
import Development7 from '../../Photos/Development7.png';
import Combind1 from '../../Photos/Combind1.png';
import Combind4 from '../../Photos/Combind4.png'
import Combind3 from '../../Photos/Combind3.png';
import Webdevelop from '../../Photos/Webdevelop.png'
import T01 from '../../Photos/01.png'
import T02 from '../../Photos/02.png'
import T03 from '../../Photos/03.png'
import T04 from '../../Photos/04.png'
import T05 from '../../Photos/05.png'
import T06 from '../../Photos/06.png';
import MobileDev1 from '../../Photos/MobileDev1.gif';
import Techmapperz1 from '../../Photos/Techmapperz1.png';
import MobileDev2 from '../../Photos/MobileDev2.gif';
import MobileDev3 from '../../Photos/MobileDev3.png';
import MobileDev4 from '../../Photos/MobileDev4.png';
import MobileDev5 from '../../Photos/MobileDev5.png';
import MobileDev6 from '../../Photos/MobileDev6.png';
import MobileDev7 from '../../Photos/MobileDev7.png';
import MobileDev8 from '../../Photos/MobileDev8.png';
import MobileDev9 from '../../Photos/MobileDev9.png';
import MobileDev10 from '../../Photos/MobileDev10.png';
import MobileDev11 from '../../Photos/MobileDev11.png';
import MobileDev12 from '../../Photos/MobileDev12.png';
import MobileDev13 from '../../Photos/MobileDev13.png';
import MobileDev14 from '../../Photos/MobileDev14.png';
import MobileDev15 from '../../Photos/MobileDev15.png';
import MobileDev16 from '../../Photos/MobileDev16.gif';
// import { Helmet } from "react-helmet";





const Mobile_development = () => {
    useEffect(() => {
        const options = {
            top: 0,
            left: 0,
            behavior: 'smooth'
        };
        window.scrollTo(options);
    }, []);

    return (
        <div className={styles.Mobile_development}>
            {/* <Helmet> */}
        <title>{"Techmapperz"}</title>
        <meta name="description" content={"Techmapperz map your business"} />
        <meta property="og:title" content={"Techmapperz"} />
      {/* </Helmet> */}
            <section className={styles.first_part}>
                <h1>Mobile Application Development</h1>
                <p>Home / Service / Mobile Application Development</p>
            </section>


            <section className={styles.secand_part}>
                <p>In this digital era,it's not easy to live without smart devices, especially smartphones, Smart devices are transforming the way we live our lives. where mobile apps have become the most lucrative digital tool to make life easier and more conveient.
                    The mobile app software intelligently combines the latest  technology, and design solutions which constantly get updated. It incorporates API, GPS, Camera, Microphone, Artificial Intelligence and other latest technologies and becomes a Launchpad hurling your business to the next level.</p>

                <div className={`${styles.contaner} ${styles.contaner_card2} `}>

                    <div className={styles.product}>
                        <div className={styles.product_info}>
                            <h1 className={styles.heading1}>Our Mobile App Development Services</h1>
                            <p>We provide IOS and Android mobile application development services to loverage mobile technology for your business. we provide IOS and Android mobile application development services </p>
                            <h2>Android App Development</h2>
                            <ul>
                                <li><p>Android Mobile App Development</p></li>
                                <li><p>Android TV App Development</p></li>
                                <li><p>Android Tablet App Development</p></li>
                                <li><p>Android Wear App Development</p></li>
                            </ul>
                            <p>Market Development-Android has more then 80% of the market share in the handheld device market share in the handheld device market, making it arguably the most popular market</p>
                        </div>
                        <div className={styles.divimg1}>
                            <img src={MobileDev1} alt="MobileDev1.png" />
                        </div>

                    </div>
                </div>


                <div className={`${styles.contaner} ${styles.contaner_card3} `}>
                    {/* <div className={`${styles.product} ${styles.contaner_product3} `}> */}
                    <div className={`${styles.product} ${styles.product3} `}>
                        <div className={styles.devimg2}>
                            <img src={MobileDev2} alt="MobileDev2.png" />
                        </div>

                        <div className={`${styles.product_info} ${styles.product_info1}`}>

                            <h1 className={styles.heading2}>iOS App Development</h1>

                            <ul>
                                <li><p>iphone App Development</p></li>
                                <li><p>iPad App Development</p></li>
                                <li><p>Apple Watch App Development</p></li>
                                <li><p>Apple TV App Development</p></li>
                                <li><p>App Clips Development</p></li>
                            </ul>
                            <p>Source-Apple's stringent data protection standards makes sensitive corporate data lodged in iPhone applications  very safe and secure</p>
                        </div>

                    </div>
                </div>



                <div className={`${styles.contaner} ${styles.contaner_card4} `}>
                    <h1 className={`${styles.heading3} ${styles.class2}`}>Types of Mobile Application</h1>
                    <div className={styles.main}>
                        <div className={`${styles.card} ${styles.main_card2}`}>
                            <img src={MobileDev3} alt='' />
                            <h3>Native App</h3>
                            <p>These apps are applications built for a specific mobile platform. For example, Apple native applications are only accessible on Apple devices, and users may only access applications from other cell phone providers on their own branded devices. Native apps may offer higher performance compared to more generalised apps.</p>
                        </div>

                        <div className={styles.card}>
                            <img src={MobileDev4} alt='Combind3.png' />
                            <h3>Web-based App</h3>
                            <p>A web-based app is internet-dependent and stores all application data online. The type code can be Javascript, HTML5 or CSS, requiring much less space on your device to function.</p>
                        </div>


                        <div className={styles.card}>
                            <img src={MobileDev5} alt='' />
                            <h3>Hybrid App</h3>
                            <p>Hybrid apps pool the effectiveness of native apps and web-based apps, and support both types of technology. These apps are swift and easy to create on the developer's end, but they often have a lower performance rate and therefore provide a less appreciated experience for the end-user.</p>

                        </div>

                    </div>
                </div>





                <div className={styles.harbour}>
                    <div>
                        <h1 className={`${styles.heading4} ${styles.class2}`}>How Mobile Applications  Grow Your  Business?</h1>
                        <img className={styles.animation} src={MobileDev16} alt='' />
                    </div>
                    <div className={styles.design}>

                        <div className={styles.card1}>
                            <div className={styles.testimg1}>
                                <img src={MobileDev6} alt='' />
                                <img className={styles.num} src={T01} alt='' />
                            </div>

                            <h3>Market to Study</h3>
                            <p>we generally conduct market research in
                                determining your exact requirements and what your rivals are
                                doing and what are their strengths and shortcomings so that we
                                can avoid making the same mistakes.</p>
                        </div>

                        <div className={styles.card2}>
                            <div className={styles.testimg1}>
                                <img src={MobileDev7} alt='' />
                                <img className={styles.num} src={T02} alt='' />
                            </div>


                            <h3>Generate Sales</h3>
                            <p>We focus an newer avenues in the marketplace for the execution of mobile applications to genetdte sales prograssive levels within a short span of time.</p>
                        </div>


                        <div className={styles.card3}>
                            <div className={styles.testimg1}>
                                <img src={MobileDev8} alt='' />
                                <img className={styles.num} src={T03} alt='' />
                            </div>

                            <h3>Innovative User Interface</h3>
                            <p>Our design team conceptualizes the most hassle-free user interface, we take special care to make it user-friendly</p>
                        </div>


                        <div className={styles.card4}>
                            <div className={styles.testimg1}>
                                <img src={MobileDev9} alt='' />
                                <img className={styles.num} src={T04} alt='' />
                            </div>


                            <h3>Business Solution</h3>
                            <p>According to your need and market reserch, we design a framework for your app,we keep in mind all your requirenments and what we have learned from the market reserch.</p>
                        </div>


                        <div className={styles.card5}>
                            <div className={styles.testimg1}>
                                <img src={MobileDev10} alt='' />
                                <img className={styles.num} src={T05} alt='' />
                            </div>


                            <h3>Brand Value</h3>
                            <p>Mobile apps are a one-stop point for getting all the relevent information related to the brand as well as showcasing the products and services. Thus increasing the over all Brand Value.</p>
                        </div>

                        <div className={styles.card6}>
                            <div className={styles.testimg1}>
                                <img src={Techmapperz1} alt='' />
                                <img className={styles.num} src={T06} alt='' />
                            </div>

                            <h3>Revenue</h3>
                            <p>We take your suggections to incorporate in-app advertising, in-app purchases, and paid apps are some of the most common app monetization tactics.</p>
                        </div>

                    </div>
                </div>





                <div className={styles.contaner}>
                    <h1 className={`${styles.heading} ${styles.class2}`}>Why Choose Us</h1>
                    <div className={styles.main}>
                        <div className={styles.card}>
                            <img src={MobileDev10} />
                            <h3>Flexibility</h3>
                            <p>We understand your need, and sometime we know that it can we time critical yet should affordable, that is why we have custome designed packages to suit your demands of time and money.</p>
                        </div>

                        <div className={`${styles.card}  ${styles.class1}`}>
                            <img src={MobileDev11} />
                            <h3>Friendly</h3>
                            <p>Nothing is more important that a customer oriented business model, we at Techmapperz love what we do, and we are always passionate about putting our customers first.</p>
                        </div>


                        <div className={styles.card}>
                            <img src={MobileDev12} />
                            <h3>Quality</h3>
                            <p>Quality makes us excel. our approach is designed to provide it at all levels of functioning up-to the micro level details, that extra ounce of effort in effective communication and best practices separates us and makes our work from Good to Great.</p>
                        </div>

                        <div className={styles.card}>
                            <img src={MobileDev13} />
                            <h3>Reduce cost</h3>
                            <p>Our team planning is so efficient that the project overall time in not extended and thus keeping the overall coast in check.</p>
                        </div>
                        <div className={styles.card}>
                            <img src={MobileDev14} />
                            <h3>Security</h3>
                            <p>Our team planning is so efficient that the project overall time in not extended and thus keeping the overall coast in check</p>
                        </div>
                        <div className={styles.card}>
                            <img src={MobileDev15} />
                            <h3>Transparency</h3>
                            <p>For most of us our business is our baby,we want the best for it, and this is the driving emotion for our transparent work culture, all our development stages are discussed, so that the client is aware of time, manpower and progress pace.</p>
                        </div>
                    </div>
                </div>



            </section>
        </div>
    )
}
export default Mobile_development
