import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SinglePost.css";
import Styles from "./Singale_article.module.css"
import CommentForm from "./CommentForm";
import { Link, useParams } from "react-router-dom";
import { AiFillLinkedin,AiFillTwitterSquare,AiFillFacebook } from 'react-icons/ai';



function SinglePost() {
  const [post, setPost] = useState([]);
  const[RelatedPosts,setRelatedPosts] = useState();
  const[Resent,setResent] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const postId = id;




  const fetchPost = async () => {
    try {
      const response = await axios.get( `${process.env.REACT_APP_Backend_baseUrl}/Newblogpost/singalepost/${postId}`)
      setPost(response.data);
      console.log(response.data)
      fetchrelatedPosts(response.data._id);
    } catch (err) {
      setError("Failed to fetch post");
    }
  };


  const fetchrecentPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Backend_baseUrl}/Newblogpost/recent`
      );
      setResent(response.data)
    } catch (err) {
      setError("Failed to fetch posts");
    }
  };
  const fetchrelatedPosts = async (id) => {
    console.log(post)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Backend_baseUrl}/Newblogpost/related/${id}`
      );
        setRelatedPosts(response.data)
      
    } catch (err) {
      setError("Failed to fetch posts");
    }
  };


  useEffect(() => {
    fetchPost();
    fetchrecentPosts();
    console.log("Fetching post");
  },[id]);



  useEffect(() => {
    const options = {
      top: 0,
      left: 0,
      behavior: 'smooth'
    };
    window.scrollTo(options);
  }, [postId]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!post) {
    return <p>Loading...</p>;
  }

  return (
    <div className={Styles.Singalearticle}>
       {/* <Helmet> */}
        <title>{post&&post.title}</title>
        <meta name="description" content={post&&post.content&&post.content.substring(0, 155)} />
        <meta property="og:title" content={post&&post.title&&post.title} />
        <meta property="og:description" content={post&&post.content&&post.content.substring(0, 155)} />
        <meta property="og:image" content={post&&post.images&&post.images[0]} />
      {/* </Helmet> */}
      <div className={Styles.top_part}>
        <h1>Article</h1>
        <p> <Link to={"/"}>Home</Link> / <Link to={"/article"}>News & Article</Link>/Article</p>
      </div>
      <div className={Styles.contaner_main}>
        <div className={Styles.contaner_left}>
          <div className={Styles.singale_post}>
            <img src={post&&post.images&&post.images[0]} />
            <div className={Styles.post_metadata}>
              <div>{`BY ${post&&post.author&&post.author.name.toUpperCase()}`}</div>
              <div>{`COMMENTS ${post&&post.comments&&post.comments.length}`}</div>
              <div>{`${post&&post.created_at&&post.created_at.split("T")[0]}`}</div>
              {/* <div>{`CATEGORY ${post&&post.category&&post.category.toUpperCase()}`}</div> */}
            </div>
            <div className={Styles.title}>{post&&post.title&&post.title}</div>
            <div className={Styles.contant}>{post&&post.content&&post.content}</div>
            <div  dangerouslySetInnerHTML={{ __html:post&&post.maincontent&& post.maincontent}} />
          </div>

          <div className={Styles.comments_list}>
            <h1>Comments</h1>
            {post&&post.comments&&post.comments.map(com=>{
              return(
                <div className={Styles.comment}>
                  {console.log(com.name)}
                  <div className={Styles.avater}>{com.name.split("")[0].toUpperCase()}</div>
                  <div>
                    <div className={Styles.comment_name}>{com.name}</div>
                    <div> {com.content}</div>
                    </div>
                </div>
              )
            })}
          </div>
          <CommentForm postId={post._id} fetchPost={fetchPost}/>
        </div>
        <div className={Styles.contaner_rigth}>

            <div className={Styles.author_details}>
              <img src={post&&post.author&&post.author.picture}/>
              <h1>{post&&post.author&&post.author.name}</h1>
              <h3>{post&&post.author&&post.author.authorDetails}</h3>
              <div className={Styles.socialLinks}>
                <Link target={"_blank"} to={post&&post.author&&post.author.socialLinks.facebook&&post.author.socialLinks.facebook}><AiFillFacebook/></Link>
                <Link target={"_blank"} to={post&&post.author&&post.author.socialLinks.twitter&&post.author.socialLinks.twitter}><AiFillTwitterSquare/></Link>
                <Link target={"_blank"} to={post&&post.author&&post.author.socialLinks.linkedin&&post.author.socialLinks.linkedin}><AiFillLinkedin/></Link>
              </div>
            </div>


            <div className={Styles.releted_post}>
            <h1 className={Styles.recent_contaner_heading}>Releted Posts</h1>
            {RelatedPosts&&RelatedPosts.length!==0?RelatedPosts.map((recent)=>{
              return(
                <div className={Styles.recentpost}>
                  <img src={recent.images[0]}/>
                    <div>
                      <h1><Link to={`/singal_article/${recent._id}`}>{recent.title.slice(0, 100) + "..."}</Link></h1>
                      <h1>{`${recent.created_at.split("T")[0]}`}</h1>
                    </div>
                </div>
              )
            }):<h1 >No related post found</h1>
            }
            </div>
            <div className={Styles.resent_post}>
            <h1 className={Styles.recent_contaner_heading}>Recent Posts</h1>
            {Resent&&Resent.map((recent)=>{
              return(
                <div className={Styles.recentpost}>
                  <img src={recent.images[0]}/>
                    <div>
                      <h1><Link to={`/singal_article/${recent._id}`}>{recent.title.slice(0, 100) + "..."}</Link></h1>
                      <h1>{`${recent.created_at.split("T")[0]}`}</h1>
                    </div>
                </div>
              )
            })}
            </div>
            
            <div className={Styles.category}></div>
        </div>
            
      </div>
     
    </div>


  );
}

export default SinglePost;
