import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Multi_carouel.css";
import whitespreadfoods from '../../../Photos/whitespreadfoods_logo.png';
import Shrc from '../../../Photos/Shrc_logo.png';
import Novusterchsurvery from '../../../Photos/Novusterchsurvery_logo.png';
import manusherghorbari from '../../../Photos/manusherghorbari_logo.png';
import Cocreatelab from '../../../Photos/Cocreatelab_logo.png';
import Pas from '../../../Photos/PAS.webp';
import Genesys from '../../../Photos/Genesys.webp';
import English_Faculties from '../../../Photos/English_Faculties.webp';
let Images = [
   Pas,
   Genesys,
   English_Faculties,
    Cocreatelab,
    manusherghorbari,
    Novusterchsurvery,
    Shrc,
    whitespreadfoods
]

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};
const Multi_carousel = () => {
    return (
        <>
        <h1 className='multi_carousel_headind'>Our Happy Clients</h1>
        <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} 
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1500}
            keyBoardControl={true}
            customTransition="all .5s ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
            {Images.map(img => {
                return (
                    <img src={img} alt='' />
                )
            })}

        </Carousel>
        </>
    )
}

export default Multi_carousel
