import React from 'react';
import  "./Modal.css";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className={"modalOverlay"}>
            <div className={"modalContent"}>
            <button onClick={onClose} className={"closeButton"} aria-label="Close">&times;</button>
                {children}
            </div>
        </div>
    );
};

export default Modal
